/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnalyticsContextProvider } from './src/analytics/AnalyticsContext';
import { AuthProvider } from './src/utils/auth/authContext';
import { authConfig } from './src/utils/auth/authConfig';
import type { GatsbyBrowser } from 'gatsby';


// libs
import 'focus-visible';
import 'normalize.css';
import './src/styles/global.scss';

// adds portal for search overlay
const portal = document?.createElement('div');
portal.id = 'portal';
document?.body.appendChild(portal);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <QueryClientProvider client={queryClient}>
     <AnalyticsContextProvider>
      <AuthProvider authConfig={authConfig}>{element}</AuthProvider>
    </AnalyticsContextProvider>
  </QueryClientProvider>
);
